<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="gywm-top">
        <div class="stfw-topeng">Contact us</div>
        <div class="stfw-topbt">联系我们</div>
      </div>
      <div class="lxwm-bg"></div>
      <div class="lxwm-main">
        <div class="lxwm-dt">
           <div id="map" style="width:900px;height:360px;"></div>
        </div>
        <div class="lxwm-dzmain">
          <div class="lxwm-dzicon"><img src="../assets/lxwm-dz.png"/></div>
          <div class="lxwm-dzxx">无锡市 新吴区 菱湖大道200号 中国传感网国际创新园 A座302室</div>
          <div class="clear"></div>
        </div>
        <div class="lxwm-xxmain">
          <div class="lxwm-xxleft">
            <div class="lxwm-xxicon"><img src="../assets/kfrx.png"/></div>
            <div class="lxwm-ewm">400 6080 717</div>
            <div class="lxwm-xxts">电话咨询</div>
          </div>
          <div class="lxwm-xxright">
            <div class="lxwm-xxicon"><img src="../assets/ewm-wx.png"/></div>
            <div class="lxwm-ewm"><img src="../assets/footer-ewm.png"/></div>
            <div class="lxwm-xxts">商务合作</div>
          </div>
          <div class="lxwm-xxright">
            <div class="lxwm-xxicon"><img src="../assets/ewm-gzh.png"/></div>
            <div class="lxwm-ewm"><img src="../assets/footer-ewm.png"/></div>
            <div class="lxwm-xxts">掌薪俱乐部</div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>



<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
export default {
  data () {
    return {
    }
  },
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd
  },
  methods:{
     init() {
      let address = "";
      let that = this;
      var center = new qq.maps.LatLng(31.502433,120.366121);
      var map = new qq.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: 13,
        disableDefaultUI: true
      });
      var marker = new qq.maps.Marker({
        position: center,
        map: map
      });
      var infoWin = new qq.maps.InfoWindow({
        map: map
      });
      var geocoder = new qq.maps.Geocoder({
        complete: function(res) {
          console.log(res);
          address = res.detail.nearPois[0].name;
        }
      });
      qq.maps.event.addListener(map, "click", function(event) {
        this.longitude = event.latLng.getLat();
        this.latitude = event.latLng.getLng();
        console.log(event);
        let lat = new qq.maps.LatLng(this.longitude, this.latitude);
        geocoder.getAddress(lat);
        setTimeout(() => {
          infoWin.open();
          infoWin.setContent(
            '<div style="text-align:center;white-space:nowrap;">' +
              address +
              "</div>"
          );
          infoWin.setPosition(event.latLng);
        }, 200);
      });
      //设置Marker的可见性，为true时可见,false时不可见，默认属性为true
            marker.setVisible(true);
      //设置Marker是否可以被拖拽，为true时可拖拽，false时不可拖拽，默认属性为false
            marker.setDraggable(false);
      //设置标注的名称，当鼠标划过Marker时显示
            marker.setTitle("无锡市 新吴区 菱湖大道200号 中国传感网国际创新园 A座302室");
      //设置Marker的动画属性为从落下
            marker.setAnimation(qq.maps.MarkerAnimation.BOUNCE);
      //添加信息窗口
            var info = new qq.maps.InfoWindow({
                map: map
            });
      //获取标记的可拖动属性
            info.open();
            info.setContent('无锡掌薪网络科技有限公司');
            info.setPosition(marker.getPosition());
    }
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped>
@media (max-width:900px) {
  .bg-main{ width: 900px;}
  .lxwm-bg{ min-width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.gywm-top{
  color: #fff;
  height: 150px;
  background: url(../assets/lxwm-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.lxwm-bg{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 360px;
  margin-top: 280px;
  background: url(../assets/lxwm-bg.png) no-repeat;
  background-size: 100% 100%;
}
.lxwm-main{
  width: 900px;
  margin: 0 auto;
  position: relative;
  z-index: 99;
}
.lxwm-dzmain{
  padding:40px 0px 40px 0px;
}
.lxwm-dt{
  width: 100%;
  height: 360px;
  background-color: #efefef;
  box-shadow: 0px 0px 10px #ddd;
  margin-top: 40px;
}
.lxwm-dzicon{
  float: left;
  width: 28px;
  height: 35px;
  margin-left: 60px;
  margin-right: 30px;
}
.lxwm-dzicon img{
  width: 100%;
  height: 100%;
  margin-top: 12px;
}
.lxwm-dzxx{
  float: left;
  line-height: 60px;
  width: 560px;
  font-size: 30px;
   color: #fff;
}
.lxwm-xxmain{
  padding: 80px 0px 40px 0px;
}
.lxwm-xxleft{
  float: left;
  width: 276px;
  border: 2px solid rgb(246, 246, 246);
  background-color: rgb(252, 252, 252);
  padding: 40px 0 20px;
}
.lxwm-xxright{
  float: left;
  width: 276px;
  margin-left: 30px;
  border: 2px solid rgb(246, 246, 246);
  background-color: rgb(252, 252, 252);
  padding: 40px 0 20px;
}
.lxwm-xxicon{
  width: 40px;
  height: 40px;
  margin: 0px auto 40px;
}
.lxwm-xxicon img{
  width: 100%;
  height: 100%;

}
.lxwm-xxleft .lxwm-ewm{
  height: 120px;
  width: 200px;
  margin: 0 auto;
  text-align: center;
  color: rgb(93, 186, 122);
  font-size: 24px;
}
.lxwm-xxright .lxwm-ewm{
  height: 120px;
  width: 120px;
  margin: 0 auto;
}
.lxwm-ewm img{
  height: 100%;
}
.lxwm-xxts{
  text-align: center;
  margin-top: 40px;
  color: rgb(100, 100, 100);
  font-size: 16px;
}
</style>