<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="stfw-top">
        <div class="stfw-topeng">Ecological services</div>
        <div class="stfw-topbt">生态服务<span>(互联服务)</span></div>
      </div>
      <div class="stfw-main">
        <div class="stfw-list">
          <div class="stfw-listleft"><img src="../assets/stfw/st-dz.png"></div>
          <div class="stfw-listright">
            <div class="stfw-listbt">定制企业公众号</div>
            <div class="stfw-listxx">
              我们可根据企业自身定位；<br/>
              为您定制符合企业形象的专属私有服务号；<br/>
              您可在服务号展示公司历史、文化及产品、服务；<br/>
              聚合各类办公系统，实现智能化、现代化管理；<br/>
              直接触达员工手机终端，提升员工参与度及满意度；<br/>
              有利于快速传播企业文化，提高员工凝聚力。
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="stfw-list">
          <div class="stfw-listleft"><img src="../assets/stfw/st-dyy.png"></div>
          <div class="stfw-listright">
            <div class="stfw-listbt">公众号代运营</div>
            <div class="stfw-listxx">
              没有才思泉涌，不会拍照；有了服务号，反而增加了负担？<br/>
              别担心，我们将为您跟拍摄影、摄像<br/>
              为您实时完成新闻报道，产品包装推文<br/>
              确保您的公众号时尚、现代、科技
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="stfw-list">
          <div class="stfw-listleft"><img src="../assets/stfw/st-bx.png"></div>
          <div class="stfw-listright">
            <div class="stfw-listbt">商业保险</div>
            <div class="stfw-listxx">
              对接平安、太平洋、阳光、人保多家知名保险公司<br/>
              年单、月单自由选择<br/>
              随时加减保减少成本浪费
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="stfw-list">
          <div class="stfw-listleft"><img src="../assets/stfw/st-bd.png"></div>
          <div class="stfw-listright">
            <div class="stfw-listbt">背景调查+电子合同</div>
            <div class="stfw-listxx">
              一键身份背调，避免引狼入室<br/>
              无纸化签署，便于存档及管理
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>
<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.stfw-top{
  color: #fff;
  height: 150px;
  background: url(../assets/stfw-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.stfw-main{
  width: 900px;
  margin: 0 auto;
}
.stfw-listleft{ height: 280px;width: 500px;text-align: center; padding: 20px 0;}
.stfw-listright{ width: 400px; padding: 5% 0;}
.stfw-list:nth-child(odd) .stfw-listleft{
float: left;
}
.stfw-list:nth-child(odd) .stfw-listright{
float: left;
}
.stfw-list:nth-child(even) .stfw-listleft{
float: right;
}
.stfw-list:nth-child(even) .stfw-listright{
float: right;
text-align: right;
}
.stfw-list img{
  height: 240px;
}
.stfw-listbt{
  font-size: 22px;
  color:#333333;
  margin-bottom: 20px;;
}
.stfw-listxx{
  font-size: 14px;
  color:#666666;
  line-height: 30px;
}
</style>