<template>
    <el-menu :default-active="this.$route.path" router class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="/xct"><div class="cpjs-list xct"></div>薪财通</el-menu-item>
      <el-menu-item index="/xzt"><div class="cpjs-list xzt"></div>薪资条</el-menu-item>
      <el-menu-item index="/zpy"><div class="cpjs-list zpy"></div>招聘云</el-menu-item>
      <el-menu-item index="/yggl"><div class="cpjs-list yggl"></div>员工管理<span>(数字化)</span></el-menu-item>
      <el-menu-item index="/lgpt"><div class="cpjs-list lgpt"></div>灵工平台</el-menu-item>
    </el-menu>
    
</template>

<script>
export default {
    name:"cpjshd",
    data() {
      return {

      };
    },
    methods: {
      handleSelect(key, keyPath) {
      }
    }
}
</script>

<style scoped>
.el-menu{ width: 900px; margin: 40px auto 0px auto;}
.el-menu.el-menu--horizontal{
    border-bottom: 0px;
}
.el-menu--horizontal > .el-menu-item.is-active{
    border-bottom: 0px;
    background-color: #2a69f6;
    border-radius: 50px 50px 0px 0px;
    color: #fff;
    padding-top: 30px;
    width: 100px;
    text-align: center;
}
.el-menu--horizontal > .el-menu-item{
    color:#333;
    font-size: 16px;
    height: 116px;
    line-height: 18px;
    padding-top: 30px;
    width: 100px;
    text-align: center; 
    margin-right: 40px;
    margin-left: 40px;
}
.el-menu--horizontal > .el-menu-item span{
    display:block;
    position: relative;
    font-size: 12px;
    color:#999;
    line-height: 18px;
}
.el-menu--horizontal > .el-menu-item.is-active span{
    color: #fff;
}
.cpjs-list{
    width: 26px;
    height: 26px;
    background-size: 100% 100%;
    margin: 0 auto 10px auto;
}
.xct{
    background-image: url(../assets/cpjs/xct01.png);
}
.el-menu-item.is-active .xct{
    background-image: url(../assets/cpjs/xct02.png)!important;
}
.xzt{
    background-image: url(../assets/cpjs/xzt01.png);
}
.el-menu-item.is-active .xzt{
    background-image: url(../assets/cpjs/xzt02.png)!important;
}
.zpy{
    background-image: url(../assets/cpjs/zpy01.png);
}
.el-menu-item.is-active .zpy{
    background-image: url(../assets/cpjs/zpy02.png)!important;
}
.yggl{
    background-image: url(../assets/cpjs/yggl01.png);
}
.el-menu-item.is-active .yggl{
    background-image: url(../assets/cpjs/yggl02.png)!important;
}
.lgpt{
    background-image: url(../assets/cpjs/lgpt01.png);
}
.el-menu-item.is-active .lgpt{
    background-image: url(../assets/cpjs/lgpt02.png)!important;
}
</style>