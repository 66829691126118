<template>
    <div>
      <el-menu :default-active="this.$route.path" router class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="/mtbd">媒体报道</el-menu-item>
      <el-menu-item index="/cpdt">产品动态</el-menu-item>
      <el-menu-item index="/hyzx">行业资讯</el-menu-item>
      </el-menu>
      <div class="xwdt-pic"><img src="../assets/cpjs/xwdt-01.png"/></div>
      
    </div>
    
</template>

<script>
export default {
    name:"xwdthd",
    data() {
      return {
      };
    },
    methods: {
      handleSelect(key, keyPath) {
      }
    }
}
</script>

<style scoped>
.el-menu{
    width: 570px;
    margin: 60px auto 0px auto;
    padding: 0px 165px;
    background-color:  rgba(255,255,255,0);
}
.el-menu.el-menu--horizontal{
    border-bottom: 0px;
}
.el-menu--horizontal > .el-menu-item.is-active{
    border-bottom: 0px;
    background-color: #2a69f6;
    border-radius: 50px;
    color: #fff;
    width: 150px;
    text-align: center;
}
.el-menu--horizontal > .el-menu-item{
    color:#333;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    width: 150px;
    text-align: center; 
    margin-right: 20px;
    margin-left: 20px;
}
.el-menu--horizontal > .el-menu-item span{
    display:block;
    position: relative;
    font-size: 12px;
    color:#999;
    line-height: 18px;
}
.el-menu--horizontal > .el-menu-item.is-active span{
    color: #fff;
}
.xwdt-pic{
  width: 180px;
  margin-left: 40px;  
}
.xwdt-pic img{
  width: 100%;
  margin-top: -50px;
}
</style>