<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="cpjs-top">
        <div class="stfw-topeng">Product introduction</div>
        <div class="stfw-topbt">产品介绍</div>
      </div>
      <div class="cpjs-main">
        <div class="cpjs-icon">
        <cpjshd></cpjshd>
        </div>
        <div class="cpjs-zpybg">
          <div class="cpjs-zpymain">
            <div class="cpjs-zpylist">
              <div class="cpjs-zpyleft"><img src="../assets/cpjs/zpy-01.png"/></div>
              <div class="cpjs-zpyright">一键发布<span>招募需求信息</span></div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-zpylist">
              <div class="cpjs-zpyleft"><img src="../assets/cpjs/zpy-02.png"/></div>
              <div class="cpjs-zpyright">在线投递<span>个人简历</span></div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-zpylist">
              <div class="cpjs-zpyleft"><img src="../assets/cpjs/zpy-03.png"/></div>
              <div class="cpjs-zpyright">建立企业<span>私域简历库</span></div>
              <div class="clear"></div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>

<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import cpjshd from '../components/Cpjs'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [cpjshd.name]:cpjshd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.cpjs-top{
  color: #fff;
  height: 150px;
  background: url(../assets/cpjs-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.cpjs-icon{
  width: 100%;
  border-bottom: 2px solid #2a69f6;

}
.cpjs-zpybg{
  background: url(../assets/cpjs/zpybg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.cpjs-zpymain{
  width: 740px;
  margin: 0 auto;
  padding: 100px 80px 180px 80px;
}
.cpjs-zpylist{
  margin-bottom: 40px;
}
.cpjs-zpyleft{
  width: 80px;
  height: 80px;
  float: left;
  margin-right: 40px;
}
.cpjs-zpyleft img{
  width: 100%;
  height: 100%;
}
.cpjs-zpyright{
  height: 80px;
  line-height: 80px;
  float: left;
}
.cpjs-zpyright span{
  color:#2a69f6;
  margin-left: 10px;
}
</style>