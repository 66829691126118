<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="cpjs-top">
        <div class="stfw-topeng">Product introduction</div>
        <div class="stfw-topbt">产品介绍</div>
      </div>
      <div class="cpjs-main">
        <div class="cpjs-icon">
        <cpjshd></cpjshd>
        </div>
        <div class="cpjs-xztmain">
          <div class="cpjs-xztbg">
            <div class="cpjs-xztlist xctbg01">
              <div class="cpjs-xztleft">01</div>
              <div class="cpjs-xztmiddle">支持多格式excel工资表上传</div>
              <div class="cpjs-xztright"><img src="../assets/cpjs/xzt-01.png"/></div>
            </div>
            <div class="cpjs-xztlist xctbg02">
              <div class="cpjs-xztleft">02</div>
              <div class="cpjs-xztmiddle">不断优化算法驱动,直接生成工资条</div>
              <div class="cpjs-xztright"><img src="../assets/cpjs/xzt-02.png"/></div>
            </div>
            <div class="cpjs-xztlist xctbg03">
              <div class="cpjs-xztleft">03</div>
              <div class="cpjs-xztmiddle">多通道一键群发工资条</div>
              <div class="cpjs-xztright"><img src="../assets/cpjs/xzt-03.png"/></div>
            </div>
            <div class="cpjs-xztlist xctbg04">
              <div class="cpjs-xztleft">04</div>
              <div class="cpjs-xztmiddle">支持短信、微信和双端提示及查看,还可及时电子签收</div>
              <div class="cpjs-xztright"><img src="../assets/cpjs/xzt-04.png"/></div>
            </div>
            <div class="cpjs-xztlist xctbg05">
              <div class="cpjs-xztleft">05</div>
              <div class="cpjs-xztmiddle">多项防护措施为您的数据保驾护航</div>
              <div class="cpjs-xztright"><img src="../assets/cpjs/xzt-05.png"/></div>
            </div>
            <div class="cpjs-xztlist xctbg06">
              <div class="cpjs-xztleft">06</div>
              <div class="cpjs-xztmiddle">提供加密传输、阿里云保户、个人信息交叉验证等保障</div>
              <div class="cpjs-xztright"><img src="../assets/cpjs/xzt-06.png"/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>

<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import cpjshd from '../components/Cpjs'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [cpjshd.name]:cpjshd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.cpjs-top{
  color: #fff;
  height: 150px;
  background: url(../assets/cpjs-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.cpjs-icon{
  width: 100%;
  border-bottom: 2px solid #2a69f6;

}
.cpjs-xztmain{
  width: 900px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.cpjs-xztbg{
  padding: 40px;
  background-color: #f3f6fd;
}
.cpjs-xztlist{
  height: 100px;
  margin-bottom: 20px;
  
}
.cpjs-xztlist:last-child{
  margin-bottom: 0;
}
.xctbg01{
  background:url(../assets/cpjs/xztbg-01.png);
  background-size: 100% 100%;
}
.xctbg02{
  background:url(../assets/cpjs/xztbg-02.png);
  background-size: 100% 100%;
}
.xctbg03{
  background:url(../assets/cpjs/xztbg-03.png);
  background-size: 100% 100%;
}
.xctbg04{
  background:url(../assets/cpjs/xztbg-04.png);
  background-size: 100% 100%;
}
.xctbg05{
  background:url(../assets/cpjs/xztbg-05.png);
  background-size: 100% 100%;
}
.xctbg06{
  background:url(../assets/cpjs/xztbg-06.png);
  background-size: 100% 100%;
}
.cpjs-xztleft{
  float: left;
  width: 60px;
  height: 60px;
  background-color: #fff;
  color:#2a69f6;
  font-size: 28px;
  line-height: 60px;
  text-align: center;
  vertical-align: middle;
  margin: 20px 40px 20px 20px;
}
.cpjs-xztmiddle{
  float: left;
  color: #fff;
  font-size: 16px;
  line-height: 100px;
}
.cpjs-xztright{
  float: right;
  height: 90px;
  margin-top: 10px;
}
.cpjs-xztright img{
  height: 100%;
}
</style>