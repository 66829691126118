<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="cpjs-top">
        <div class="stfw-topeng">Product introduction</div>
        <div class="stfw-topbt">产品介绍</div>
      </div>
      <div class="cpjs-main">
        <div class="cpjs-icon">
        <cpjshd></cpjshd>
        </div>
        <div class="cpjs-ygglbg">
          <div class="cpjs-ygglmain">
            <div class="cpjs-yggllist">
              <div class="cpjs-ygglleft"><img src="../assets/cpjs/yggl-01.png"></div>
              <div class="cpjs-ygglright">
                <div class="cpjs-ygglbt">无纸化  入职/转正/离职</div>
                <div class="cpjs-ygglxx">
                无纸化办理入职手续，节约HR录入时间<br/>
                提高HR办理效率，减少重复工作<br/>
                人事表单高效办理员工入离转正等人事流程
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-yggllist">
              <div class="cpjs-ygglleft"><img src="../assets/cpjs/yggl-02.png"></div>
              <div class="cpjs-ygglright">
                <div class="cpjs-ygglbt">无纸化 管理</div>
                <div class="cpjs-ygglxx">
                无纸化档案管理，大数据管控员工档案<br/>
                快速收集员工信息
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>

<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import cpjshd from '../components/Cpjs'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [cpjshd.name]:cpjshd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.cpjs-top{
  color: #fff;
  height: 150px;
  background: url(../assets/cpjs-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.cpjs-icon{
  width: 100%;
  border-bottom: 2px solid #2a69f6;
  box-shadow: 0px 10px 6px #efefef;

}
.cpjs-ygglbg{
  background: url(../assets/cpjs/ygglbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cpjs-ygglmain{
  width: 900px;
  margin: 0 auto;
}
.cpjs-ygglleft{ height: 280px;width: 500px;text-align: center; padding: 20px 0;}
.cpjs-ygglright{ width: 400px; padding: 5% 0;}
.cpjs-yggllist:nth-child(odd) .cpjs-ygglleft{
float: left;
}
.cpjs-yggllist:nth-child(odd) .cpjs-ygglright{
float: left;
}
.cpjs-yggllist:nth-child(even) .cpjs-ygglleft{
float: right;
}
.cpjs-yggllist:nth-child(even) .cpjs-ygglright{
float: right;
text-align: right;
}
.cpjs-ygglleft img{
  height: 240px;
}
.cpjs-ygglbt{
  font-size: 22px;
  color:#333333;
  margin-bottom: 20px;
  padding-left: 30px;
}
.cpjs-ygglbt::before{
  content: " ";
  position: absolute;
  margin-top: 3px;
  margin-left: -30px;
  height: 26px;
  width: 10px;
  background-color: #2a69f6;
  border-radius: 50px;
}
.cpjs-ygglxx{
  font-size: 14px;
  color:#666666;
  line-height: 40px;
}

</style>