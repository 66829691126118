<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="gywm-top">
        <div class="stfw-topeng">News information</div>
        <div class="stfw-topbt">新闻动态</div>
      </div>
      <div class="xwxq-main">
        <div class="xwxq-btmain">
          <div class="xwxq-bt">{{graphic.graphicName}}</div>
        </div>
        <div class="xwxq-xx"></div>
        <div class="xwxq-rq">{{ dateFormat("YYYY-mm-dd HH:MM", graphic.createTime ) }}</div>
        <div class="xwxq-ms" v-html="graphic.graphicContent"></div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>
<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import {graphicdetail} from '../api/graphic'
import { dateFormat } from "../js/date"
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
  },
  data(){
    return{
      graphic:'',
      graphicId:'',
    }
  },
  mounted(){
    this.graphicId = this.$route.params.id
    this.getGraphic()
  },
  methods:{
    dateFormat,
    getGraphic(){
      graphicdetail(this.graphicId).then((res) =>{
        this.graphic = res.data
      })
    }
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.gywm-top{
  color: #fff;
  height: 150px;
  background: url(../assets/xwdt-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.xwxq-main{
    width: 900px;
    margin: 0 auto;
    padding: 40px 0px;
}
.xwxq-btmain{
    text-align: center;
    width: 900px;
}
.xwxq-bt{
    padding: 0 20px;
    color:#333;
    font-size: 24px;
    background-color: #fff;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 99;
}
.xwxq-xx{
    position:relative;
    margin-top: -25px;
    width: 900px;
    height: 1px;
    border-top: 2px solid #2a69f6;
    z-index: 0;
}
.xwxq-rq{
    color:#999;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 25px;
}
.xwxq-ms{
    font-size: 15px;
    line-height: 25px;
    color:#333;
    text-align: justify;
}
</style>


