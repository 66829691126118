<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="gywm-top">
        <div class="stfw-topeng">About us</div>
        <div class="stfw-topbt">关于我们<span>互联网+人力资源技术服务公司</span></div>
      </div>

      <div class="gywm-banner">
        <div class="gywm-bannermain">
          <div class="gywm-bannerleft">
          <div class="gywm-bannerbt">“掌”上协同链接 赋能共享创“薪”</div>
          <div class="gywm-bannerms">
            Internet +<br/>
            human resources technology <br/>
            service company
          </div>
        </div>
        <div class="gywm-bannerright"></div>
        <div class="clear"></div>
        </div>
      </div>

      <div class="gywm-bg"></div>
      <div class="gywm-main">
        <div class="gywm-list">
          <div class="gywm-listleft1"><img src="../assets/gywm-01.png"/></div>
          <div class="gywm-listright">
            掌薪科技——是基于团队多年来对于人力资源管理与行政服务、行业政策、市场格局的深刻理解，结合移动互联网思维、薪资金融服务及其他多方位的模式创新，为广大企业客户与人力资源服务机构提供定制化服务的一家人力资源技术服务公司。
          </div>
          <div class="clear"></div>
        </div>
        <div class="gywm-list">
          <div class="gywm-listleft">
            <div class="gywm-listbt">TO 企业</div>
            <div>
            掌薪科技可为广大企业提供人力资源6大模块各信息化模块的链接整合、数据共享协同服务，可与sap、workday、用友及各类软件系统进行数据对接共享；<br/><br/>
            同时基于员工个人社交账号将食堂、班车、宿舍、图书馆、调研、工会、党建等行政事务集合后进行一站式服务，消除企业人力资源管理与服务过程中的数据孤岛现象，提升服务效率、降低管理成本；
            </div>
          </div>
          <div class="gywm-listright">
            <div class="gywm-listrighttext">
            掌薪科技可以为广大人力资源服务机构的招聘、入职、考勤、审批、培训、薪酬管理、政策合规等各个模块提供信息化解决方案，提升各个人力资源服务机构的外包管理、人事代理、薪酬结算、灵活用工等多种人力资源服务业务的服务水平，增加服务能力和客户粘性。
            </div>
            <div class="gywm-listbt1">TO 机构</div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="gywm-list"> 
          <div class="gywm-listleft">
            <div class="gywm-weilai">未来衍生共享</div>
            <div>
            掌薪科技未来将不遗余力的联手众多人力资源服务机构及友商打造<span>“雇主管理”与“员工服务”</span>两个共享服务生态，让更多的企业尤其是广大中小企业都有能力和机会以亲民的成本甚至免费享受到高附加值的人力资源服务，让员工享受增值服务的同时融入到企业运营管理当中来，创新用工模式、融洽员工关系、协助构建和谐社会。<br/><br/>
            协同链接、赋能共享、创赢未来，掌薪科技将以人力资源服务模式创新为使命、以技术赋能为抓手、引导我国人力资源行业积极主动的拥抱移动互联网与人工智能等前沿思维和技术，为中华产业腾飞实现弯道超车贡献绵薄之力。
            </div>
          </div>
          <div class="gywm-listright1"><img src="../assets/gywm-02.png"/></div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>
<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
  .gywm-banner{ min-width: 900px;}
  .gywm-bg{ min-width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.gywm-top{
  color: #fff;
  height: 150px;
  background: url(../assets/gywm-top.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 99;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.gywm-main{
  position: relative;
  z-index: 0;
  width: 900px;
  margin: 310px auto 0;
  padding-bottom: 10px;
}
.gywm-banner{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 400px;
  margin-top: -150px;
  background-color: #f6f9ff;
}
.gywm-bannermain{
  width: 900px;
  margin: 200px auto 0 auto;
}
.gywm-bannerleft{
  float: left;
  width: 500px;
  margin: auto;
}
.gywm-bannerbt{
  font-weight: bold;
  color: #2a69f6;
  font-size: 24px;
  margin-bottom: 20px;
}
.gywm-bannerms{
  font-size: 20px;
  font-weight: bold;
}
.gywm-bannerright{
  float: left;
  width: 400px;
  height: 220px;
  margin-top: -20px;
  background: url(../assets/gywm-bg01.png) no-repeat right;
  background-size: auto 100%;
}
.gywm-list{
  color: #333;
  margin-bottom: 70px;
  line-height: 30px;
  font-size: 14px;
}
.gywm-list img{
  width: 100%;
}
.gywm-list span{
  color: #2a69f6;
}
.gywm-listleft{
  float: left;
  width: 350px;
  margin-left: 0px;
}
.gywm-listleft1{
  float: left;
  width: 350px;
}
.gywm-listleft1 img{
  margin-left: 50px;
}
.gywm-listright{
  float: left;
  width: 350px;
  margin-left:200px;

  
}
.gywm-listright1{
  float: left;
  width: 400px;
  margin-left:100px;
  margin-right: 50px;
  padding-top: 40px;
}
.gywm-listbt{
  font-size: 18px;
  color: #fff;
  background-color: #2a69f6;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 36px;
  border: 6px solid #fff;
  margin-top: 20px;

}
.gywm-listbt1{
  font-size: 18px;
  color: #fff;
  background-color: #2a69f6;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 50px;
  border: 6px solid #fff;
  margin-top: 90px;
  margin-left: 190px;

}
.gywm-listrighttext{
  margin-top: 60px;
}
.gywm-bg{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 520px;
  margin-top: 550px;
  background: url(../assets/gywm-bg02.png) no-repeat;
  background-size: 100% 100%;
}
.gywm-weilai{
  font-size: 24px;
  color: #2a69f6;
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>