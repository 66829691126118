<template>
  <div class="icons">
    <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide v-for='item in page' :key="item.id">
          <div v-for="page in item" :key="page.id"  class="icons-item">
            <img :src="page.imgUrl">
            <p>{{page.text}}</p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script type="text/javascript">
import { swiper, swiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css';
export default {
  data(){
    return {
      iconsList:[
        {
          id:"01",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"酒店"
        },
        {
          id:"02",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/flight.png",
          text:"机票"
        },
        {
          id:"03",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/train.png",
          text:"火车票"
        },
        {
          id:"04",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/package.png",
          text:"度假"
        }
        ,{
          id:"05",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"景点门票"
        }
        ,{
          id:"06",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"景点门票"
        }
        ,{
          id:"07",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"景点门票"
        }
        ,{
          id:"08",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"景点门票"
        }
        ,{
          id:"09",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"景点门票"
        }
        ,{
          id:"10",
          imgUrl:"//s.qunarzz.com/homenode/images/touchheader/hotel.png",
          text:"景点门票"
        }
      ],
      swiperOption:{
        loop: true,
        autoplay: true,
        //  设置分页器
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          }
        }
      }
  },
  computed:{
    page(){
      let pages = [];
      this.iconsList.forEach((item,index)=>{
        let idx = Math.floor(index/8)
        if(!pages[idx]) pages[idx] =[];
        pages[idx].push(item)
      })
      return pages
    }
  }
}
</script>



<style>
  .icons{
  width:900px;
  overflow: hidden;
}
.icons-item{
  width: 225px;
  height: 0;
  padding-bottom: 25%;
  float: left;
}
.icons-item img{
  width: 110px;
  height: 110px;
  display: block;
  margin: 0 auto;
  padding-top: .2rem;
}
.icons-item p{
  margin-top: .1rem;
  font-size: 16px;
  text-align: center;
  color: #212121;
}

</style>