<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="gywm-top">
        <div class="stfw-topeng">News  information</div>
        <div class="stfw-topbt">新闻动态</div>
      </div>
      <div class="xwdt-main">
        <xwdthd></xwdthd>
        <ul class="xwdt-listmain">
        <li v-for="data in datalist.slice( 
            (currentPage - 1) * query.pageSize,
            currentPage * query.pageSize 
        )" 
        :key="data .graphic"
        @click="handleChangePage(data.graphicId)">
            <div class="pt">{{ data.graphicName }}</div>
            <div class="sj">
              {{ dateFormat("YYYY-mm-dd HH:MM", data.createTime ) }}
            </div>
            <div class="clear"></div>
        </li>
        </ul>
        <!-- <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination> -->
        <el-pagination 
          :current-page="currentPage" 
          :page-sizes="[1, 10, 20, 30]"
          :page-size="query.pageSize" 
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          />
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>
<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import xwdthd from '../components/Xwdt'
import {graphic} from '../api/graphic'
import { dateFormat } from "../js/date"

export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [xwdthd.name]:xwdthd
  },
  data(){
    return{
      datalist: [],
      query:{
        pageCurrent:1,
        pageSize:10,
        queryName:'',
        typeId:3
      },
      currentPage: 1,
      total:0,
    }
  },
  mounted(){
    this.getAllData();
  },
  methods:{
    dateFormat,
    getAllData() {
      graphic(this.query).then((res) => {
        if (res.code === 200) {
          this.datalist = res.data.records  
          this.total = res.data.totalCount
        } else {
          this.datalist = []
        }
      });
    },
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getAllData();
    },
    handleCurrentChange(val) {
      this.query.pageCurrent = val;
      this.getAllData();
    },
    
    // 进入详情页
    handleChangePage(id) {
      this.$router.push({ name:'xwxq',params:{id:id}})
    },
  },
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.gywm-top{
  color: #fff;
  height: 150px;
  background: url(../assets/xwdt-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.xwdt-main{
    width: 900px;
    margin: 0 auto;
}
.xwdt-listmain{
  width: 820px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #ddd;
  padding: 20px 40px;
  background-color: #fff;
  margin-top: -5px;
}
.xwdt-listmain ul {
  text-decoration: none;
}
.xwdt-listmain li {
  font-size: 14px;
  color:#333;
  line-height: 35px;
  text-decoration: none;
}
.xwdt-listmain li:hover{
  color:#2a69f6;
}
.xwdt-listmain li:hover::before{
  content: " ";
  position: absolute;
  width: 0;
  height:0;
  margin-left: -15px;
  margin-top: 11px;
  border-top:6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid#2a69f6;
}
.el-pagination {
    padding: 40px 0px;
    color: #333;
    text-align: center;
    font-weight: normal;
}
.pt{
  float: left;
}
.sj{
  float: right;
}
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2a69f6;
  border: 0;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #fff;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  border-radius: 50%;
  border: 1px solid #ddd;
  color: #333;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color:#2a69f6;
}
</style>