<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="cpjs-top">
        <div class="stfw-topeng">Product introduction</div>
        <div class="stfw-topbt">产品介绍</div>
      </div>
      <div class="cpjs-main">
        <div class="cpjs-icon">
        <cpjshd></cpjshd>
        </div>
        <div class="cpjs-jsmain">
          <div class="cpjs-jsbt">优化银行发薪方式</div>
          <div class="cpjs-jsms">
            <div class="cpjs-jsleft">
              · 无需收集员工银行卡信息<br/>
              · 支持自由捆绑银行账户<br/>
              · 无需额外支付跨行支付手续、异地支付手续<br/>
              · 支持无卡发薪
            </div>
            <div class="cpjs-jsright"><img src="../assets/cpjs/fxfs.png"/></div>
            <div class="clear"></div>
          </div>
          <div class="cpjs-jsbt">更轻松的理财方式</div>
          <div class="cpjs-jsms">
            <div class="cpjs-jsleft">
              · 获得银行额外利息收入<br/>
              · 变成本中心为利润中心
            </div>
            <div class="cpjs-jsright"><img src="../assets/cpjs/lcfs.png"/></div>
            <div class="clear"></div>
          </div>
          <div class="cpjs-jsbt">资金安全无忧</div>
          <div class="cpjs-jsms">
            <div class="cpjs-jsleft">
              · 资金由银行风控保驾护航<br/>
              · 薪资由企业账户直接转入员工个人任意银行账户<br/>
              · 无第三方接触资金，银行风控保障，安全无忧
            </div>
            <div class="cpjs-jsright"><img src="../assets/cpjs/zjaq.png"/></div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>

<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import cpjshd from '../components/Cpjs'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [cpjshd.name]:cpjshd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.cpjs-top{
  color: #fff;
  height: 150px;
  background: url(../assets/cpjs-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.cpjs-icon{
  width: 100%;
  border-bottom: 2px solid #2a69f6;
  box-shadow: 0px 10px 20px #efefef;
}
.cpjs-main{
  padding-bottom: 40px;
}
.cpjs-jsmain{
  width: 900px;
  margin: 0 auto;
}
.cpjs-jsbt{
  font-size: 22px;
  line-height: 24px;
  padding-left: 30px;
  margin: 40px 0px 30px 0px;
}
.cpjs-jsbt::before{
  content: " ";
  position: absolute;
  margin-left: -30px;
  height: 26px;
  width: 10px;
  background-color: #2a69f6;
  border-radius: 50px;
}
.cpjs-jsms{
  width: 780px;
  padding: 10px 60px;
  box-shadow: 0px 5px 15px #ddd;
  display: table;
}
.cpjs-jsleft{
  width: 480px;
  color:#666;
  font-size: 15px;
  line-height: 35px;
  display: table-cell;
  vertical-align: middle;
}
.cpjs-jsright{
  float: left;
  height: 200px;
  width: 300px;
}
.cpjs-jsright img{
  height: 100%;
  width: auto;
}
</style>