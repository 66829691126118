<template>
  <div>
    <navhd></navhd>
    <div class="bg-main">
      <div class="cpjs-top">
        <div class="stfw-topeng">Product introduction</div>
        <div class="stfw-topbt">产品介绍</div>
      </div>
      <div class="cpjs-main">
        <div class="cpjs-icon">
        <cpjshd></cpjshd>
        </div>
        <div class="cpjs-lgptmain">
          <div class="cpjs-lgptleft">
            <div class="cpjs-lgptpic"><img src="../assets/cpjs/lgpt-01.png"/></div>
            <div class="cpjs-lgptbt">提高灵工管理合规能力</div>
          </div>
          <div class="cpjs-lgptright">
            <div class="cpjs-lgptlist">
              <div class="cpjs-lgpticon"><img src="../assets/cpjs/lgpt-02.png"/></div>
              <div class="cpjs-lgptms">支持 定制灵工众包平台</div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-lgptlist">
              <div class="cpjs-lgpticon"><img src="../assets/cpjs/lgpt-02.png"/></div>
              <div class="cpjs-lgptms">支持 企业/代理自助发布灵工招募信息</div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-lgptlist">
              <div class="cpjs-lgpticon"><img src="../assets/cpjs/lgpt-02.png"/></div>
              <div class="cpjs-lgptms">支持 灵工在线应聘</div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-lgptlist">
              <div class="cpjs-lgpticon"><img src="../assets/cpjs/lgpt-02.png"/></div>
              <div class="cpjs-lgptms">支持 实时记录灵工工时，自动计算日薪</div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-lgptlist">
              <div class="cpjs-lgpticon"><img src="../assets/cpjs/lgpt-02.png"/></div>
              <div class="cpjs-lgptms">支持 日薪、周薪发放</div>
              <div class="clear"></div>
            </div>
            <div class="cpjs-lgptlist">
              <div class="cpjs-lgpticon"><img src="../assets/cpjs/lgpt-02.png"/></div>
              <div class="cpjs-lgptms">支持 完善场景证据链管理及保存</div>
              <div class="clear"></div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <footerhd></footerhd>
  </div>
</template>

<script>
import navhd from '../components/Navmenu'
import footerhd from '../components/Footer'
import cpjshd from '../components/Cpjs'
export default {
  components:{
    [navhd.name]:navhd,
    [footerhd.name]:footerhd,
    [cpjshd.name]:cpjshd
  }
}
</script>

<style scoped>
@media (max-width:900px) {
  .bg-main{width: 900px;}
}
.bg-main{
  background-color: #fff;
}
.clear{ clear: both;}
.cpjs-top{
  color: #fff;
  height: 150px;
  background: url(../assets/cpjs-top.png) no-repeat;
  background-size: 100% 100%;
}
.stfw-topeng{
  font-size: 15px;
  width: 840px;
  padding-left: 60px;
  padding-top: 40px;
  margin: 0px auto;
  letter-spacing: 3px; 
}
.stfw-topbt{
  font-size: 24px;
  width: 840px;
  padding-left: 60px;
  margin: 0px auto;
}
.stfw-topbt span{
  font-size: 12px;
  padding-left: 10px;
}
.cpjs-icon{
  width: 100%;
  border-bottom: 2px solid #2a69f6;
  box-shadow: 0px 10px 6px #efefef;
}
.cpjs-lgptmain{
  width: 900px;
  margin: 0 auto;
  padding: 100px 0px;
}
.cpjs-lgptleft{
  float: left;
  width: 450px;
}
.cpjs-lgptright{
  float: left;
  width: 450px;
  line-height: 55px;
}
.cpjs-lgptpic{
  width: 400px;
  margin: 0 auto;
}
.cpjs-lgptpic img{
  width: 100%;
  height: auto;
}
.cpjs-lgptbt{
  font-size: 18px;
  height: 45px;
  width: 250px;
  text-align: center;
  margin: 40px auto 0 auto;
  line-height: 45px;
  color: #fff;
  background-color: #2a69f6;
  border-radius: 50px;
}
.cpjs-lgpticon{
  float: left;
  width:30px;
  margin-right: 20px;
}
.cpjs-lgpticon img{
  width: 100%;
}
.cpjs-lgptms{
  font-size: 16px;
}
</style>